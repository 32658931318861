.header-container{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 0px 20px;
    
}

.header {
    background-color: #454545;
    border-radius: 50px;
    padding: 3px 50px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  
  .nav-list {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
    gap: 30px;
    
  }
  

  .nav-item a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    /* color: white; */
    color: rgb(255, 255, 255);
  }
  
  .nav-item a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 1.5px;
    background-color: #ffffff; 
    transition: width 0.3s ease;
  }
  
  
  .nav-item a:hover::after {
    width: 100%;
  }

  
.contact-button {
  background-color: #454545;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 768px) {
  
  .header{display: none;}
}
  