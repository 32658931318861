@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bungee+Spice&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Varela+Round&display=swap');

.brand-container{
    margin: 13px;
    padding: 0 0 1% 0;

}

/* Overall Banner Container */
.modern-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
  background-color: rgb(34, 34, 34);
  padding: 0 50px;
  color: #ffffff;
  overflow: hidden;
  border-radius: 25px;
}

/* Content Wrapper */
.banner-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}

/* Text Content */
.text-content {
  text-align: left;
  animation: fadeInLeft 1.5s ease-in-out;
}

.brand-heading {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #f3b619;
  font-family: "Varela Round", sans-serif;
}

.brand-subheading {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.brand-description {
  font-size: 1.2rem;
  margin: 20px 0;
  line-height: 1.6;
}

/* CTA Button */
.cta-button {
  font-size: 1.2rem;
  padding: 12px 30px;
  background-color: #f3b619;
  border: none;
  border-radius: 5px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.3s, background-color 0.3s;
}

.cta-button:hover {
  background-color: #ff8c00;
  transform: scale(1.1);
}

/* Image Content */
.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.repairmen-img {
  width: 100%;
  max-width: 650px;
  border-radius: 10px;
  animation: fadeInRight 1.5s ease-in-out;
}

/* Animations */
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


/* Marquee*/
.marquee-wrapper {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
    
  }
  
  .marquee-item {
    display: flex;
    animation: marquee 100s linear infinite;
    
   
  }
  
  .marquee-repeater {
    display: inline-block;
    padding-right: 10px;
    
  }
  
  .focused-text {
    font: 500 100px/1.5 Inter;
    color: #2ca8fe;
    margin: 0;
  }
  
  @keyframes marquee {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }
  

/* our-services */
.services{
  margin-bottom: 50px;
    
}

.service-heading{
    font-family: 'Inter', sans-serif;
    color: #999;
    font-weight: 500;
    padding-inline: 40px;

}

.service-para{
    color: #999;
    padding-inline: 40px;
    font: 400 28px / 1.15 Inter;
    margin: 0;
}

/* Page container */
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  background-image: url('../../assets/18662958.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Card container to align cards */
.card-container {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

/* Card styling */
.card {
  background: #fff;
  border-radius: 20px !important;
  overflow: hidden;
  width: 300px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

/* Image container */
.card-img-container {
  height: 180px;
  overflow: hidden;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  width: fit-content;
  transition: transform 0.3s ease;
}

.card:hover .card-img {
  transform: scale(1.1);
}

/* Card content */
.card-content {
  padding: 1rem;
}

.card-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
}

.card-description {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.4;
  margin-bottom: 1rem;
}

/* Tags */
.card-tags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.card-tag {
  background: #f4f4f4;
  color: #333;
  font-size: 0.75rem;
  padding: 0.4rem 0.8rem;
  border-radius: 8px;
  white-space: nowrap;
}


.image-content-layout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 40px;
  gap: 20px;
}

.image-content-layout-image-section {
  flex: 1;
}

.image-content-layout-image {
  width: 600px;
  height: auto;
  border-radius: 8px;
}

.image-content-layout-content-section {
  flex: 2;
}

.image-content-layout-title {
  margin-bottom: 10px;
}

.image-content-layout-text {
  line-height: 1.6;
  font-size: 1.2rem;
  color: #555;
}

.about-us-container{
  background-color:#f4f4f4 ;
  padding: 1px 0 40px 0;
  margin-top: 20px;
}

.about-us{
margin-top: 50px;

}

.about-us-content{
  margin-left: 30px;
}

.about-us-head::after {
  content: "";
  display: block;
  width: 100%;
  height: 2.5px;
  background: #c1c0c0;  
  
}


.about-para{
  padding-inline: 40px;
  /* margin: 40px 0 40px 0; */
  font-size: 17px;
  font-family: 'Inter', sans-serif;
}

.image-right-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
}

.content {
  flex: 1;
  max-width: 50%;
  padding-right: 20px;
}

.content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #222;
}

.content p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}

.image {
  flex: 1;
  max-width: 50%;
  text-align: center;
}

.image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Section Heading */
.section-heading {
  text-align: center;
  font-size: 3em;
  color: #333;
  margin-bottom: 10px;
  font-family: "Varela Round", sans-serif;
  margin-top: 0;
}

.section-description {
  text-align: center;
  font-size: 1em;
  color: #666;
  margin-bottom: 30px;
}

/* Repair Process Cards */
.repair-process {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.card-inch {
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  text-align: center;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 4px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-repair {
  border-radius: 8px;
  padding: 20px;
  color: #fff;
  text-align: center;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.card-repair:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}


.card-repair-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.card-icon{
  mix-blend-mode: multiply;
}


.card-repair-button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #fff;
  color: #333;
  font-size: 0.9em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card-repair-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
}


.card-repair-extra {
  margin-top: 10px;
  font-size: 0.9em;
  color: #f1f1f1;
}

.step-1 {
  background-color: #181818;

}

.step-2 {
  background-color: #dadada; 
  color: black;
}

.step-3 {
  background-color: #181818; 
}

.step-4 {
  background-color: #dadada;
  color: black;
}

.contact-float {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.main-icon img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.main-icon img:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.contact-options {
  position: absolute;
  bottom: 70px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s ease;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 50px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.contact-float:hover .contact-options {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.contact-option {
  margin: 10px 0;
  width: 40px;
  height: 40px;
}

.contact-option img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-option img:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Footer Styles */
.footer {
  background-color: #222;
  color: #fff;
  padding: 40px 0 10px 0;
  margin-top: 50px;
  font-family: Arial, sans-serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.footer-about,
.footer-links,
.footer-contact,
.footer-social {
  flex: 1;
  min-width: 250px;
}

.footer-about h2,
.footer-links h3,
.footer-contact h3,
.footer-social h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.footer-about p,
.footer-contact p,
.footer-links ul li a {
  font-size: 14px;
  line-height: 1.6;
  text-decoration: none;
  color: #fff;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 5px;
}

.footer-links ul li a:hover {
  color: #f39c12; 
}

.footer-social .social-icons {
  display: flex;
  gap: 10px;
}

.footer-social .social-icons a {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}

.footer-social .social-icons a:hover {
  color: #f39c12;
}

.footer-divider {
  border: none;
  height: 2px;
  background: rgba(255, 255, 255, 0.2);
  margin: 20px 0;
}

.footer-copyright {
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

  
.faq-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.faq-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.faq-item {
  border: 1px solid #dddddd;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-item.active {
  border-color: #007bff;
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.2);
}

.faq-question {
  padding: 15px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  transition: all 0.3s ease;
}

.faq-question:hover {
  background: #f0f8ff;
}

.faq-icon {
  font-size: 1.5rem;
  color: #007bff;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  background: #ffffff;
  transition: max-height 0.3s ease;
}

.faq-answer p {
  padding: 15px 20px;
  font-size: 0.9rem;
  color: #666666;
  line-height: 1.6;
}
/* Container Styles */
.reviews-container {
  max-width: 1200px;
  margin: 50px auto;
  text-align: center;
  overflow: hidden;
  padding: 20px;
}

.reviews-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

/* Wrapper Styles */
.reviews-wrapper {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px;
  scrollbar-width: none;
}

.reviews-wrapper::-webkit-scrollbar {
  display: none;
}

/* Card Styles */
.review-card {
  min-width: 280px;
  max-width: 280px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease;
}

.review-card:hover {
  transform: scale(1.05);
}

/* Avatar and Name Styles */
.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.avatar {
  width: 50px;
  height: 50px;
  /* background-color: #007bff; */
  background: linear-gradient(135deg, #ff9800, #ff5722);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 15px;
  text-transform: uppercase;
}

.review-name {
  font-size: 1.2rem;
  font-weight: 600;
  /* color: #007bff; */
  color: #ff9800;
}

.review-text {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.5;
}


/* Responsive Design */
@media screen and (max-width: 768px) {
  .focused-text{
    font-size: 60px;
  }
  .modern-banner {
    flex-direction: column;
    padding: 20px;
    height: auto;
  }

  .banner-content {
    flex-direction: column;
    gap: 20px; 
  }

  .text-content {
    text-align: center; 
    animation: none; 
  }

  .brand-heading {
    font-size: 3rem; 
    margin-top: 0;
  }

  .brand-subheading {
    font-size: 1.5rem;
  }

  .brand-description {
    font-size: 1rem; 
    line-height: 1.4; 
  }

  .cta-button {
    font-size: 1rem;
    padding: 10px 20px;
    margin-bottom: 20px;
  }

  .image-content {
    order: -1;
    margin-bottom: 20px;
  }

  .repairmen-img {
    max-width: 100%;
  }

  .footer-container{
    padding: 30px;
  }

  .service-para{
    font-size: 1.2rem;

  } 

  .image-content-layout-container {
    flex-direction: column;
    text-align: center;
  }

  .image-content-layout-image {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .mob-text-res{
    text-align: justify;
  }
  
}

@media screen and (max-width: 480px) {
  .brand-heading {
    font-size: 2.5rem; 
  }

  .brand-subheading {
    font-size: 1.2rem;
  }

  .brand-description {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}

.why {
  padding: 20px 20px;
  background: #f4f4f9;
}

.why-choose-us {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 40px;
  color: #333;
}

.feature-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  align-items: center;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-direction: row;
}


.icon-container {
  /* width: 80px; */
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.feature-item h3 {
  font-size: 1.5rem;
  color: #0078ff;
  margin-bottom: 10px;
}

.feature-item p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .why {
    padding: 10px;
  }

  .why-choose-us {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .feature-grid {
    grid-template-columns: 1fr; 
  }

  .feature-item {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-inline: 20px;
  }

  .feature-item img {
    margin-bottom: 15px;
  }

  .feature-item h3 {
    font-size: 1.25rem; 
    margin-top: 0;

  }

  .feature-item p {
    font-size: 0.9rem; 
  }

  .about-us-section {
    flex-direction: column;
    text-align: center;
  }
  .about-us-section img {
    margin-bottom: 20px;
  }

  .about-us-heading{
    margin-top: 0;
  }
  .about-us-content{
    margin-left: 0;
  }
}